import React from 'react'
import { Link } from 'gatsby'

import SEO from 'src/components/seo'
import { PLUS_BUY_LINK } from 'src/utils/constants'
import { PageContainer, PageTitle } from '../../templates/ShopifyPage/styles'

const Support = () => (
  <>
    <SEO
      title="User Guide & Troubleshooting Support | Skylight Frame"
      description="User support guide for setting up your Skylight Frame, sending photos, other features, troubleshooting, security, and Skylight Plus."
      path="/pages/support"
    />
    <PageContainer>
      <PageTitle>Skylight User Guide</PageTitle>
      <h3>Go to Section</h3>
      <ol>
        <li>
          <a href="#SettingUpSkylight">Setting Up Skylight</a>
        </li>
        <li>
          <a href="#SendingPhotos">Sending Photos</a>
        </li>
        <li>
          <a href="#Features">Features</a>
        </li>
        <li>
          <a href="#Troubleshooting">Troubleshooting</a>
        </li>
        <li>
          <a href="#Security">Security</a>&nbsp;
        </li>
        <li>
          <a href="#SkylightPlus">Skylight Plus</a>&nbsp;
        </li>
      </ol>

      <div id="SettingUpSkylight">
        <br />
        <h3>
          1. <strong>SETTING UP SKYLIGHT</strong>
        </h3>
        <h4>Can I set up Skylight before gifting it to someone?</h4>
        <p>
          You can set up the email address of the frame in advance before gifting it to someone.
          Just go to{' '}
          <a href="http://www.ourskylight.com/finalstep">www.ourskylight.com/finalstep</a> to set up
          your Skylight email address and get the 6-digit activation code. Hold onto this code as
          you will need to give it to them when they receive their frame.
        </p>
        <p>When the recipient gets their Skylight, they have to:</p>
        <ol>
          <li>Plug it in</li>
          <li>
            Select their wifi network and type in the wifi password (if there is one) using
            Skylight's touchscreen
          </li>
          <li>
            Type in the 6-digit code that you received when you created your personal Skylight email
          </li>
        </ol>
        <h4>How do I connect my Skylight to WiFi?</h4>
        <p>
          If the frame is plugged in, the WiFi menu will automatically show up on the Skylight home
          screen. Choose your network from the list of WiFi networks that appears on the right side
          of the screen.&nbsp;Skylight will confirm when it has connected to the WiFi.
        </p>
        <p>
          * If the name of your network does not appear, wait for 30 seconds and check again. If it
          still does not appear, tap “Forget All Networks And Rescan” at the bottom of the WiFi
          menu.
        </p>
        <p>
          * If your WiFi requires a password, type in the password using the on-screen keyboard.
          Make sure to check that it’s typed correctly!
        </p>
        <h4>How do I get my Skylight email address and 6-digit code?</h4>
        <p>Once you connect to WiFi, Skylight will ask for your 6-digit code. To retrieve it:</p>
        <ol>
          <li>
            Visit <a href="http://www.ourskylight.com/finalstep">www.ourskylight.com/finalstep</a>
          </li>
          <li>
            Choose your personal Skylight email address. Every Skylight email has the format
            ___________@ourskylight.com (e.g.,{' '}
            <a href="mailto:johnsmith@ourskylight.com">johnsmith@ourskylight.com</a>)
          </li>
          <li>
            Our system will give you a 6-digit code to tap into your Skylight device. This will
            activate the Skylight email address you chose for it.
          </li>
        </ol>
        <h4>How do I&nbsp;turn on my Skylight?</h4>
        <p>
          Connect the power adapter to Skylight (it plugs into the small hole on the back that says
          DCIN) and plug it into an outlet. In about 30 seconds, the Skylight home screen (with a
          photo of a mountain) will appear.
        </p>
        <h4>I created the wrong Skylight address. What do I do?</h4>
        <p>
          Send us an email at help@skylightframe.com&nbsp;with the Skylight email address you
          created so we can send a reset code to the frame.
        </p>
        <h4>I forgot my Skylight email address. What do I do?</h4>
        <p>To retrieve your Skylight email address:</p>
        <ol>
          <li>Tap the Skylight frame screen.</li>
          <li>Tap “Settings.”</li>
          <li>Tap "How it Works". You should find your email address there.</li>
          <li>If this does not work, email us at help@skylightframe.com</li>
        </ol>
      </div>

      <div id="SendingPhotos">
        <br />
        <h3>
          2. <strong>SENDING PHOTOS</strong>
        </h3>
        <h4>How do I send photos to Skylight?</h4>
        <p>You can send photos to Skylight as an attachment to an email. You need to:</p>
        <ol>
          <li>Open an email application like Yahoo/Gmail/Outlook</li>
          <li>Compose an email</li>
          <li>
            Find the attach button and choose the photos you want to send. If you’re on a mobile
            app, look for the “Share” button and then choose email as your method of sharing.
          </li>
          <li>
            Send the email to your Skylight email address. They will appear on the screen in less
            than 60 seconds!
          </li>
        </ol>
        <h4>Can others send photos to Skylight?</h4>
        <p>
          Everyone who you share your unique Skylight email address with can send photos (as
          attachments) to your Skylight.
        </p>
        <h4>When I send photos, does my email need to have a title or text in the body?</h4>
        <p>
          The frame does not recognize text, it only recognizes photos, so adding a subject or text
          in the body does not matter as the frame will not see it.
        </p>
        <h4>
          What are your recommended size, ratio, and resolution of photos that works best with the
          frame?
        </h4>
        <p>
          Skylight is designed to work best with landscape (horizontal) pictures that have a 16:9
          ratio. Photos taken vertically may not appear optimally.
        </p>
        <h4>Why are some my photos appearing with dark space on the sides?</h4>
        <p>
          For any photos that do not fit the 16:9 ratio, including vertical photos, Skylight will
          add black space on the sides to make them fit on the frame.&nbsp;You can pinch zoom the
          photos to adjust them to your liking.
        </p>
        <h4>My photo is not in the right orientation. Can I rotate it?</h4>
        <p>
          Yes, to rotate your photo simply tap the screen and click "rotate" on the top left side.
        </p>
        <h4>How many photos can Skylight hold? What happens when you run out of space?</h4>
        <p>
          There are 8GB of memory available on Skylight, enough for 8,000+ pictures. You can easily
          delete photos using the "Delete" feature on our touch-screen interface. When Skylight runs
          out of space, it will automatically replace your oldest photos with new ones as they come
          in.
        </p>
        <h4>How many photos can I send at a time?</h4>
        <p>
          You can email up to 31MB of photos to Skylight in one email. If you send too many large
          images in one email, you may receive this message: "552 5.3.4 Message is too long." In
          that case, please try to send the images in separate emails.
        </p>
      </div>

      <div id="Features">
        <br />
        <h3>
          3. <strong>FEATURES</strong>
        </h3>
        <h4>What other features does Skylight have?</h4>
        <p>To access additional features, tap the screen to see the Settings Bar, where you can:</p>
        <ol>
          <li>Delete photos</li>
          <li>Pause on a favorite photo</li>
          <li>View a Gallery of all your photos and navigate to a specific photo quickly</li>
          <li>Tap “How It Works” to check your Skylight email address if you forget it</li>
          <li>Reset your WiFi</li>
          <li>Change the speed of the slideshow</li>
          <li>Show your photos in chronological order or shuffle them</li>
        </ol>
        <h4>Can I put my Skylight to sleep or turn the screen off without unplugging it?</h4>
        <p>
          To put your Skylight to sleep at night, press the power button (the round button at the
          top if you look at the back of Skylight) and wait 2 seconds for the screen to power down.
          Press the same button to turn the screen back on.
        </p>
        <h4>What is the heart in the lower right corner of Skylight?</h4>
        <p>
          If you love a photo, you can tap the Heart button in the lower right of the screen, and
          we’ll email the sender to tell them you loved it!
        </p>
        <h4>Why did I receive a photo that someone liked a photo on my Skylight?</h4>
        <p>
          That means that the recipient of the photo tapped the Heart button on the photo to let you
          know that they loved what you sent! If you’d prefer not to get these emails, just let them
          know not to tap the Heart button.
        </p>
        <h4>Can I send videos to Skylight?&nbsp;</h4>
        <p>
          You can send videos to Skylight&nbsp;if you have our Skylight Plus subscription. To learn
          more&nbsp;<span>or upgrade to Skylight Plus</span>, <span>please&nbsp;</span>
          <Link to={PLUS_BUY_LINK}>click here</Link>
          <span>.</span>
        </p>
        <h4>Can I add captions to my photos?&nbsp;</h4>
        <p>
          You can add captions to your photos using our app if you have the Skylight Plus
          subscription.&nbsp;<span>To learn more&nbsp;or upgrade to Skylight Plus, </span>
          please&nbsp;
          <Link to={PLUS_BUY_LINK}>click here</Link>.
        </p>
        <h4>Can I delete photos from Skylight?&nbsp;</h4>
        <p>
          To delete a photo, tap the screen to see the Settings Bar and then tap the “Delete”
          button. Currently, there is no option to delete multiple photos, so you can only delete
          photos one by one.
        </p>
        <h4>Is there a way to adjust the brightness of the screen?</h4>
        <p>
          The frame does not have a way to adjust the brightness of the screen manually. We
          recommend sending larger and crisper photos to see if that helps the brightness. Skylight
          is sensitive to the quality of photo you send -- photos that look great on mobile usually
          look good on Skylight, but photos that look “pretty good” on mobile can sometimes don’t
          look as good on Skylight.
        </p>
        <h4>Is there a way to shuffle photos?</h4>
        <p>
          To shuffle your photos, tap the screen and click "settings". Then, click "advanced
          features" and select whether you want to show your photos in chronological order or
          shuffle them.
        </p>
        <h4>
          What are the USB port, SD card slot, headset jack and menu button at the back of the frame
          for?&nbsp;
        </h4>
        <p>
          These ports and button are not yet functional but are included with Skylight for future
          features that we are developing.
        </p>
        <h4>Do you have a gift option in your orders? Can we add a gift message to our order?</h4>
        <p>This option is not available yet.</p>
        <h4>Can I print photos from Skylight?</h4>
        <p>
          You cannot print photos from Skylight. However, if you have our Skylight Plus
          subscription, you can download them to your phone our computer using our mobile or desktop
          app. With Skylight Plus, all your photos will also be stored in the cloud.&nbsp; T
          <span>o learn more&nbsp;or upgrade to Skylight Plus,&nbsp;</span>
          <span>please&nbsp;</span>
          <Link to={PLUS_BUY_LINK}>click here</Link>
          <span>.</span>&nbsp;
        </p>
        <h4>How can I see all the photos I have sent to my frame?</h4>
        <p>
          Your photos are stored in the frame. If you&nbsp;have purchased our Skylight Plus
          subscription, you can also view your photos on the cloud portal or mobile app.&nbsp;
          <span>T</span>
          <span>o learn more&nbsp;or upgrade to Skylight Plus,&nbsp;</span>
          <span>please&nbsp;</span>
          <Link to={PLUS_BUY_LINK}>click here</Link>
          <span>.</span>
          <span>&nbsp;</span>
        </p>
        <h4>Does the Skylight Frame have a battery?</h4>
        <p>Skylight does not have a battery. It will only work if it is plugged in.</p>
        <h4>What if the person I'm gifting Skylight to doesn't have wifi?</h4>
        <p>
          Skylight only needs wifi&nbsp;to&nbsp;get set up the first time and download photos to the
          frame. All other features will work without wifi.
        </p>
        <p>
          You can set up the frame for your loved one by connecting it to a wifi hotspot (using your
          phone or another tablet with a hotspot). When the frame is not on wifi, any photos you
          send will be stored in your server.&nbsp;Then, when you visit, just connect to the hotspot
          and all the photos you've sent will get downloaded!
        </p>
      </div>

      <div id="Troubleshooting">
        <br />
        <h3>
          4. <strong>TROUBLESHOOTING</strong>
        </h3>
        <h4>Some photos are not arriving to&nbsp;my Skylight. What do I do?</h4>
        <p>To troubleshoot your Skylight:</p>
        <ol>
          <li>
            Confirm you’re sending to the correct Skylight email address. Sometimes there’s a typo.
            You can check it by tapping the screen, going to “Settings” → “How it Works”
          </li>
          <li>
            Confirm that you’re sending to _______@ourskylight.com. Sometimes people accidentally
            send to @skylight.com or @skylightframe.com, which are not correct.
          </li>
          <li>Confirm that you’re sending photos as an attachment, not as a link.</li>
          <li>Try sending photos from a different email account or device.</li>
          <li>
            Try resetting your frame (by unplugging and replugging it), as well as resetting your
            WiFi router.
          </li>
          <li>
            Try moving your Skylight closer to your WiFi router, as your signal may be a bit weak
            for Skylight to detect.
          </li>
          <li>
            If the above does not work, reach out to help
            <span style={{ fontWeight: '400' }}>@skylightframe.com</span> for support.
          </li>
        </ol>
        <h4>What to do if Skylight is not connecting to the WiFi?</h4>
        <p>
          Skylight works seamlessly for almost all WiFi environments but occasionally does run into
          trouble. One easy solution, if you have a different WiFi network, is to try connecting to
          that. Or you could try connecting to a WiFi hotspot -- if Skylight works then, that means
          it’s likely an issue with your other WiFi network. Some other suggestions for WiFi issues:
        </p>
        <ol>
          <li>Try restarting your WiFi router.</li>
          <li>Next, unplug and plug your Skylight back in.</li>
          <li>
            If your Skylight is far from your WiFi router, try moving it a bit closer, e.g. within
            15 feet.
          </li>
          <li>
            Try connecting to your network now. If it doesn't work, try a couple more times over 5
            minutes.
          </li>
          <li>
            If the above solutions don’t work, reach out to&nbsp;help@skylightframe.com
            <span>&nbsp;for support.</span>
          </li>
        </ol>
        <h4>The screen is not turning on. What do I do?&nbsp;</h4>
        <p>
          If you have another device which uses the same power adapter as the frame, please try to
          use the other adapter and see if it works. This will help us determine if the problem is
          with the power adapter or the frame itself. If this does not work, please contact us at
          help@skylightframe.com
        </p>
      </div>

      <div id="Security">
        <br />
        <h3>
          5. <strong>SECURITY</strong>
        </h3>
        <h4>Is my Skylight email secure?</h4>
        <p>
          You control who has your Skylight email address, so only give it to people who you want to
          send to your Skylight. In addition, we are able, upon request, to limit who can send
          pictures to your Skylight (Whitelisting), or block specific offending email addresses
          (Blacklisting).
        </p>
        <h4>Where are my photos stored?</h4>
        <p>
          Your photos are stored in the frame only. If you have purchased our Skylight Plus
          subscription, your photos will also be stored safely in the cloud.&nbsp;<span>T</span>
          <span>o learn more&nbsp;or upgrade to Skylight Plus,&nbsp;</span>
          <span>please&nbsp;</span>
          <Link to={PLUS_BUY_LINK}>click here</Link>
          <span>.</span>
          <span>&nbsp;</span>
        </p>
      </div>

      <div id="SkylightPlus">
        <br />
        <h3>
          6. <strong>SKYLIGHT PLUS</strong>
        </h3>
        <p>
          <span>
            Skylight Plus is our $39/year subscription that allows you to send videos to your frame,
            add captions to photos, and manage them on the Mobile App and Cloud Portal.
          </span>
        </p>
        <p>
          If you wish to upgrade to Skylight Plus, please&nbsp;
          <Link to={PLUS_BUY_LINK}>click here</Link>.
        </p>
        <p>
          If you have questions about your Skylight Plus subscription, please&nbsp;
          <a
            href="/pages/how-to-use-skylight-plus/"
            target="_blank"
            title="How to Use Skylight Plus"
            rel="noopener noreferrer"
          >
            click here to see our Skylight Plus User Guide
          </a>
          .
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <span>
              If you have any other questions, email our Customer Support team at
              help@skylightframe.com
            </span>
          </strong>
        </p>
      </div>
    </PageContainer>
  </>
)

Support.propTypes = {}

export default Support
